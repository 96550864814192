import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertProvider from '@common/contexts/Alert';
import LayoutProvider from '@common/contexts/Layout';
import { ConfigProvider, Empty } from 'antd';
import locale from 'antd/lib/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import App from './App';
import './styles/index.scss';
import './styles/theme.less';

dayjs.locale('ja');

const queryClient = new QueryClient();

const MemoizedApp = React.memo(App);

ReactDOM.render(
  <ConfigProvider
    renderEmpty={() => (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="適当な検索結果がありません"
      />
    )}
    locale={locale}
  >
    <QueryClientProvider client={queryClient}>
      <LayoutProvider>
        <AlertProvider>
          <MemoizedApp />
          <ToastContainer
            closeButton={false}
            autoClose={5000}
            hideProgressBar
            position="top-center"
          />
        </AlertProvider>
      </LayoutProvider>
    </QueryClientProvider>
  </ConfigProvider>,
  document.getElementById('root'),
);
